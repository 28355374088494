h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 40px 0 20px 0;
  font-family: var(--heading);
  font-weight: var(--semi-bold);
  text-align: left;
  letter-spacing: 1px;
}

h1 {
  margin: 40px 0;
  font-size: 1.6rem;
  font-weight: var(--regular);
  text-transform: uppercase;
  text-align: center;
}

h2 {
  font-size: 1.6rem;
  padding-bottom: 5px;
  border-bottom: solid 1px var(--light-gray);
  font-weight: var(--regular);
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.3rem;
}

h5 {
  font-size: 1.15rem;
}

h6 {
  font-size: 1rem;
}

:where(h1, h2, h3, h4, h5, h6) > .icon {
  margin-right: 1em;
  color: var(--light-gray);
}
